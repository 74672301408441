import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Bio from '../components/Bio';
import Layout from '../components/layout';
import { rhythm } from '../utils/typography';

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description',
    );
    const posts = get(this, 'props.data.allMarkdownRemark.edges');

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <Bio />
        <div>
          <h1>Hi, how are you?</h1>
          <p is="p">
            You&apos;ve ended up at a site I use to play around with new
            technologies.
          </p>
          <p is="p">
            I aim to turn this into a PWA for visualizing public data sets, and
            to navigate the issues of DOM ownership between React and D3
          </p>
          <p is="p" my={3}>
            For example, I&apos;m starting to chart
            {' '}
            <Link to="/AustinFoodScores/">
              food inspection scores from the City of Austin
            </Link>
          </p>
          <p is="p" my={3}>
            Care to look at the
            {' '}
            <a
              href="https://gitlab.com/vermin/awst.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              project source code
            </a>
            ?
          </p>
          <p is="p" my={3}>
            Or, if you use the
            {' '}
            <a
              href="https://beakerbrowser.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Beaker Browser
            </a>
            {' '}
            you can navigate to the P2P version of this site at: 
            {' '}
            <br />
            <a
              href="hyper://4d5cfe231463a15a866c918fd2cd02593a1df6f35a352bd986ee69c4a1bbbc78/"
              target="_blank"
              rel="noopener noreferrer"
            >
              hyper://4d5cfe231463a15a866c918fd2cd02593a1df6f35a352bd986ee69c4a1bbbc78
            </a>
          </p>
        </div>
        {posts.map(({ node }) => {
          const title = get(node, 'frontmatter.title') || node.fields.slug;
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          );
        })}
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
          }
        }
      }
    }
  }
`;
